:root {
    --store-font            : "Helvetica Neue", Helvetica, Arial, sans-serif;
    --store-header-bg       : #025ade;
    --store-header-text     : white;
    --store-search-bg       : #0246ac;
    --store-search-text     : white;
    --store-button-bg       : #025ade;
    --store-button-text     : white;
    --store-buy-bg          : #3fa34e;
    --store-buy-text        : white;

    --main-width            : 800px;
    --main-padding          : 24px;

    --product-columns       : 3;
    --product-width         : 220px;
    --product-gap           : 24px;

    --box-shadow            : 0 2px 8px 1px #f1f3f8;
    --border                : 1px solid #d9e1ec;
    --border-radius         : 16px;
    --border-radius-big     : 24px;
    --border-radius-small   : 12px;

    --backdrop-color        : rgba(9, 30, 66, .5);
    --z-backdrop            : 10;
}
